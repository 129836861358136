$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;
$colordark: #353353;
$coloryellow: #788a8b;
$colorpink: #ff7348;
$colorblue: #00adb5;
$colordefault: #454360;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}